import './PopupFormBuilder.scss';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import FormBuilder from '../Form/FormBuilder';
import { Modal } from '@dataartdev/uikit/Modal';
import { mutationMainAllForm } from '../../../network';
import { IRoot57 } from '../ContentBuilder/models/CTI_57';
import { FormSuccess } from '../Form';
import { cn } from 'ui/utils/bem';

export interface ModalHandler {
  show: () => void;
  hide: () => void;
}

const cnPopupFormBuilder = cn('PopupFormBuilder');

export const PopupFormBuilder = forwardRef<ModalHandler, IRoot57>(
  (props, ref) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [successForm, setSuccessForm] = useState(false);

    const { form, dictionariesForm, pageId, buttonAttr, formTitle, formText } =
      props || {};

    useImperativeHandle(ref, () => ({
      show() {
        setModalOpen(true);
      },
      hide() {
        setModalOpen(false);
      },
    }));
    return (
      <Modal
        isOpen={modalOpen}
        hasOverlay
        scroll
        centered
        view="form"
        onOverlayClick={(): void => {
          setModalOpen(false);
        }}
        onEsc={(): void => {
          setModalOpen(false);
        }}
        onClose={() => {
          setSuccessForm(false);
        }}
        showCloseButton
        inverted={successForm}
        className={cnPopupFormBuilder('Modal')}
      >
        {!successForm ? (
          <div
          // className={cnVacancyForm()}
          >
            <FormBuilder
              formTitle={formTitle}
              formText={formText}
              setSuccessForm={setSuccessForm}
              buttonAttr={buttonAttr}
              form={form}
              submitText={form?.submitText}
              dictionariesForm={dictionariesForm}
              setModalOpen={setModalOpen}
              onSubmitForm={async body =>
                await mutationMainAllForm({
                  body: {
                    ...body,
                    pageId,
                  },
                })
              }
            />
          </div>
        ) : (
          <FormSuccess
            className={cnPopupFormBuilder('Success')}
            title={form?.successPopup?.title}
            text={form?.successPopup?.text}
            link={form?.successPopup?.link}
            titleSize={'lg'}
            textSize={'m'}
            sideGap="xl"
            view=""
          />
        )}
      </Modal>
    );
  }
);
