// @ts-nocheck
import './TestimonialCard.scss';

import React from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { IconProps } from '@dataartdev/uikit/Icon';
import { Typography } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';
import { Button } from '@dataartdev/uikit/Button';

export type Props = {
  buttonIcon?: IconProps;
  text?: string;
};

const sizeMap = {
  m: 'sm',
  s: 'tiny',
};
const sizeMapUser = {
  m: null,
  s: 'xs',
};

export const cnTestimonialCard = cn('TestimonialCard');

export const TestimonialCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    quote,
    author,
    authorPhoto,
    authorPosition,
    view,
    size = 'm',
    link,
    onClickButton,
    linkText,
    isFeedback,
    flag,
    country,
  } = usePropsHandler(cnTestimonialCard(), props);

  return (
    <div className={cnTestimonialCard({ view, size })}>
      {isFeedback && (
        <>
          {quote.length > 140 && (
            <Typography.Text className={cnTestimonialCard('Title')}>
              {quote}
            </Typography.Text>
          )}
          {quote.length <= 140 && (
            <Typography.Title size="sm" className={cnTestimonialCard('Title')}>
              {quote}
            </Typography.Title>
          )}
        </>
      )}

      {!isFeedback && (
        <Typography.Title
          size={getSizeByMap(sizeMap, size)}
          className={cnTestimonialCard('Title')}
        >
          {quote}
        </Typography.Title>
      )}

      <div className={cnTestimonialCard('User')}>
        <User
          name={author}
          info={authorPosition}
          avatarUrl={authorPhoto?.url}
          size={getSizeByMap(sizeMapUser, size)}
          flag={flag}
          country={country}
        />
      </div>
      {link && link.link.includes('http') && (
        <div className={cnTestimonialCard('Button')}>
          {Object.keys(link).length > 0 && typeof link !== 'string' ? (
            <Button
              view="link-secondary"
              as="a"
              label={link.title}
              href={link.link}
              iconRight={IconOpenInNew}
              onClick={onClickButton}
              iconSize="s"
              target={link.targetBlank ? '_blank' : null}
            />
          ) : (
            <Button
              view="link-secondary"
              as="a"
              label={linkText}
              href={link}
              iconRight={IconOpenInNew}
              onClick={onClickButton}
              iconSize="s"
              target={link.targetBlank ? '_blank' : null}
            />
          )}
        </div>
      )}
      {link && !link.link.includes('http') && (
        <div className={cnTestimonialCard('Button')}>
          <Button
            view="link-secondary"
            iconSize={'m'}
            label={link.title}
            iconRight={IconArrowRight}
            isLink
            to={link.link}
          />
        </div>
      )}
    </div>
  );
});
