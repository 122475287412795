import './ServicePreviewCard.scss';
import React from 'react';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import {
  Button as IButton,
  Label,
  Picture as IPicture,
} from '../ContentBuilder/models/CTI_55';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';
import { Picture } from '@dataartdev/uikit/Picture';

export const cnServicePreviewCard = cn('ServicePreviewCard');

type Props = {
  title?: string;
  text?: string;
  button?: IButton;
  label?: Label;
  picture?: IPicture;
};

export const ServicePreviewCard: React.FC<Props> = props => {
  const { title, text, button, label, picture } = props || {};
  const { title: linkTitle, link, targetBlank } = button || {};

  const buttonAttr = prepareButtonAttrs(link, linkTitle, targetBlank);

  return (
    <div className={cnServicePreviewCard()}>
      <div className={cnServicePreviewCard('Inner')}>
        <div className={cnServicePreviewCard('Title')}>
          {title && (
            <Typography.Title
              className={cnServicePreviewCard('Heading')}
              as="h3"
              size="sm"
            >
              {title}
            </Typography.Title>
          )}
          {label && (
            <Typography.Text
              className={cnServicePreviewCard('Label')}
              size="xs"
              as="span"
              weight="bold"
              transform="uppercase"
              style={{
                background: label?.labelColor,
                color: label?.isInverted
                  ? 'var(--color-control-bg-default-hover)'
                  : '',
              }}
            >
              {label.value}
            </Typography.Text>
          )}
        </div>
        {text && (
          <Typography.Text
            className={cnServicePreviewCard('Description')}
            as="p"
            size="m"
          >
            {text}
          </Typography.Text>
        )}
        {button && (
          <Button
            className={cnServicePreviewCard('Button')}
            iconRight={IconArrowRight}
            view="secondary"
            {...buttonAttr}
          />
        )}
      </div>
      <div className={cnServicePreviewCard('Img')}>
        {picture && <Picture {...picture} />}
      </div>
    </div>
  );
};
