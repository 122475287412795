import './PromoLinkCard.scss';

import React from 'react';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Item } from '../ContentBuilder/models/CTI_80';

export const cnPromoLinkCard = cn('PromoLinkCard');

export const PromoLinkCard: React.FC<Item> = props => {
  const { title, titleLink, color, text } = props;
  return (
    <div className={cnPromoLinkCard()}>
      <div
        className={cnPromoLinkCard('Color')}
        style={{ backgroundColor: color }}
      />
      {title && titleLink ? (
        <a
          href={titleLink}
          target="_blank"
          className={cnPromoLinkCard('Title')}
        >
          <Typography.Text size="l" weight="bold" parse>
            {title}
          </Typography.Text>
        </a>
      ) : title ? (
        <Typography.Text size="l" weight="bold" parse>
          {title}
        </Typography.Text>
      ) : null}
      {text && (
        <Typography.Text size="m" className={cnPromoLinkCard('Text')} parse>
          {text}
        </Typography.Text>
      )}
    </div>
  );
};
