import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { PartnerCard } from '../../PartnerCard/PartnerCard';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { cnContentBuilderTemplate } from './index';
import { IRoot17 } from '../models/CTI_17';

export const ContentBuilderTemplate: React.FC<IRoot17> = props => {
  const { type, title, partners, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {partners && partners.length > 0 && (
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 8, rowGap: 'xl', colGap: 'm' },
            mobile: { cols: 1, gap: 'xl' },
          }}
        >
          {partners.map(item => {
            const { link, title, relativeUrl, slug } = item;
            const path = slug
              ? {
                  link: relativeUrl,
                  title: title,
                }
              : {
                  link: link?.link,
                  title: link?.title,
                  icon: null,
                  targetBlank: link?.targetBlank,
                };
            return (
              <GridItem
                breakpoints={{
                  desktop: { col: 3 },
                  tablet: { col: 4 },
                  mobile: { col: 1 },
                }}
              >
                <PartnerCard {...item} path={path} />
              </GridItem>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default ContentBuilderTemplate;
