import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { MemberCard } from '../../MemberCard/MemberCard';
import { useIntl } from 'react-intl';
import { IRoot14 } from '../models/CTI_14';

export const ContentBuilderTemplate: React.FC<IRoot14> = props => {
  const { type, title, members, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const { locale } = useIntl();

  const options = {
    watchOverflow: true,
    slidesPerView: 'auto',
    spaceBetween: 8,
    navigation: true,
    breakpoints: {
      767: {
        spaceBetween: 16,
      },
      1199: {
        spaceBetween: 24,
      },
    },
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}

      {members && members.length > 0 && (
        <Slider {...options}>
          {members.map(member => {
            return (
              <SliderItem key={member.id}>
                <MemberCard {...member} routePath={member.fullUrl} />
              </SliderItem>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default ContentBuilderTemplate;
