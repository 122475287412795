import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate110.scss';
import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { cn } from '../../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { IRoot110 } from '../models/CTI_110';

const cnRoadmapDiagram = cn('RoadmapDiagram');

export const ContentBuilderTemplate = forwardRefWithAs<IRoot110>(props => {
  const { type, contentId, title, isVertical = false, items } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnRoadmapDiagram()}>
        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 12 },
            mobile: { cols: 12 },
          }}
        >
          {title && (
            <GridItem
              breakpoints={{
                desktop: { col: 8 },
                tablet: { col: 10 },
                mobile: { col: 12 },
              }}
            >
              <Typography.Title as="h2" size="lg">
                {title}
              </Typography.Title>
            </GridItem>
          )}
          {items && items.length > 0 && (
            <GridItem
              breakpoints={{
                desktop: { col: 12 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
              className={cnRoadmapDiagram('Inner', {
                isHorizontal: !isVertical,
              })}
            >
              {items.map((item, index) => {
                const { title = '', notes = [] } = item || {};
                return (
                  <div className={cnRoadmapDiagram('Item')}>
                    <div className={cnRoadmapDiagram('Number')}>
                      {index + 1}
                    </div>
                    <div className={cnRoadmapDiagram('Content')}>
                      {title && (
                        <Typography.Title
                          parse
                          as="h3"
                          size="tiny"
                          className={cnRoadmapDiagram('Title')}
                        >
                          {title}
                        </Typography.Title>
                      )}
                      {notes.length > 0 && (
                        <ul>
                          {notes.map(note => (
                            <li key={note} className={cnRoadmapDiagram('Text')}>
                              <Typography.Text parse>{note}</Typography.Text>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                );
              })}
            </GridItem>
          )}
        </Grid>
      </div>
    </div>
  );
});

export default ContentBuilderTemplate;
