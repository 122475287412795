import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate77.scss';

import React from 'react';
import { cn } from '../../../utils/bem';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Typography } from '@dataartdev/uikit/Typography';
import { cnContentBuilderTemplate } from './index';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { LightCard } from '../../LightCard/LightCard';
import { IRoot77 } from '../models/CTI_77';

const cnCardBtnSection = cn('CardBtnSection');

export const ContentBuilderTemplate: React.FC<IRoot77> = props => {
  const { type, title, items, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnCardBtnSection()}>
        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 12 },
            mobile: { cols: 12 },
          }}
        >
          {title && (
            <GridItem
              breakpoints={{
                desktop: { col: 8 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
            >
              <Typography.Title size="lg" as="h2">
                {title}
              </Typography.Title>
            </GridItem>
          )}
        </Grid>
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 12, gap: 'xl' },
            mobile: { cols: 12, gap: 'm' },
          }}
          className={cnCardBtnSection('List')}
        >
          {items &&
            items.length > 0 &&
            items.map(item => (
              <GridItem
                key={item.id}
                breakpoints={{
                  desktop: { col: 4 },
                  tablet: { col: 4 },
                  mobile: { col: 12 },
                }}
              >
                <LightCard {...item} />
              </GridItem>
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
