// @ts-nocheck
import './ValuesCard.scss';

import React from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { Article } from '../Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography, TitleProps } from '@dataartdev/uikit/Typography';

export const cardPropSize = ['s', 'm'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[0];

export type Props = {
  photo?: object;
  title?: string;
  text?: string;
  view?: string;
  size?: CardPropSize;
};

export const cnValuesCard = cn('ValuesCard');

const sizeMapTitle: Record<TitleProps, CardPropSize> = {
  s: 'tiny',
  m: 'sm',
  l: 'md',
};

export const ValuesCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    view,
    color,
    picture,
    text,
    title,
    size = cardPropSizeDefault,
    renderContent,
    number,
    subtitle,
  } = usePropsHandler(cnValuesCard(), props);

  return (
    <div className={cnValuesCard({ size, view, number: !!number })}>
      {number && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          className={cnValuesCard('Number')}
        >
          {number}
        </Typography.Text>
      )}
      {color && (
        <div
          className={cnValuesCard('Border')}
          style={{ backgroundColor: color }}
        />
      )}
      {picture && (
        <Picture
          desktop={picture.url}
          desktopWebp={picture.urlWebp}
          className={cnValuesCard('Picture')}
        />
      )}
      {title && (
        <Typography.Title
          size={getSizeByMap(sizeMapTitle, size)}
          className={cnValuesCard('Title')}
          dangerouslySetInnerHTML={{ __html: title }}
          as="h3"
        />
      )}
      {subtitle && (
        <Typography.Text
          weight="bold"
          className={cnValuesCard('SubTitle')}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      {text && <Article className={cnValuesCard('Text')} content={text} />}
      {renderContent && (
        <div className={cnValuesCard('Content')}>{renderContent}</div>
      )}
    </div>
  );
});
