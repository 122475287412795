/* eslint-disable */
// @ts-nocheck
import './Form.scss';

import React from 'react';
import { Form as FormikForm, Formik } from 'formik';

import { cn } from 'ui/utils/bem';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { Modal } from '@dataartdev/uikit/Modal';
import { Typography } from '@dataartdev/uikit/Typography';
import { SiteBlock } from '../SiteBlock/SiteBlock';
import { Button } from '@dataartdev/uikit/Button';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';
import { IconDownload } from '@dataartdev/uikit/IconDownload';

type Form = {
  onSubmit?: () => void;
};

export const cnFormSuccess = cn('FormSuccess');

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Form = forwardRefWithAs<Form>((props, ref) => {
  return <Formik {...props}>{props.children}</Formik>;
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormBody = forwardRefWithAs((props, ref) => {
  return <FormikForm {...props}>{props.children}</FormikForm>;
});

export const FormSuccess = forwardRefWithAs(
  (
    {
      title,
      text,
      view = 'overlay',
      topGap = '2xl',
      bottomGap = '3xl',
      sideGap = 'xl',
      titleSize = 'tiny',
      textSize,
      modal,
      link,
      submitText,
    },
    ref
  ) => {
    const buttonAttr = prepareButtonAttrs(
      link?.link,
      link?.title,
      link?.targetBlank
    );
    if (modal) {
      return (
        <Modal {...modal}>
          <SiteBlock
            topGap={topGap}
            bottomGap={bottomGap}
            sideGap={sideGap}
            className={cnFormSuccess({ view })}
          >
            <Typography.Title
              size={titleSize}
              className={cnFormSuccess('Title')}
              as="h2"
            >
              {title}
            </Typography.Title>
            <Typography.Text
              className={cnFormSuccess('Text')}
              size={textSize}
              parse
            >
              {text}
            </Typography.Text>
            {Object.keys(buttonAttr).length > 0 && (
              <Button
                className={cnFormSuccess('Submit')}
                view="secondary"
                iconRight={IconDownload}
                {...buttonAttr}
              />
            )}
          </SiteBlock>
        </Modal>
      );
    }

    return (
      <SiteBlock
        topGap={topGap}
        bottomGap={bottomGap}
        sideGap={sideGap}
        className={cnFormSuccess({ view })}
      >
        <Typography.Title
          size={titleSize}
          className={cnFormSuccess('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
        <Typography.Text
          className={cnFormSuccess('Text')}
          size={textSize}
          parse
        >
          {text}
        </Typography.Text>
        {Object.keys(buttonAttr).length > 0 && (
          <Button
            className={cnFormSuccess('Submit')}
            view="secondary"
            iconRight={IconDownload}
            {...buttonAttr}
          />
        )}
      </SiteBlock>
    );
  }
);
