import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { EssenceCard } from '../../EssenceCard/EssenceCard';
import { IRoot22 } from '../models/CTI_22';

interface Props extends IRoot22 {
  aliases: { [key: string]: string };
}
export const ContentBuilderTemplate: React.FC<Props> = props => {
  const { type, title, items, contentId, aliases } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const options = {
    navigation: true,
    breakpoints: {
      767: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
    spaceBetween: 8,
    slidesPerView: 1,
    watchOverflow: true,
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      <Slider {...options}>
        {items &&
          items.map(post => {
            return (
              <SliderItem key={post.slug}>
                <EssenceCard
                  {...post}
                  view="filled"
                  postDate={
                    post.contentType === 0 || post.contentType === 2
                      ? null
                      : post.postDate
                  }
                  linkTitle={
                    post.contentType === 0
                      ? 'read more'
                      : post.contentType === 2
                      ? 'watch on youtube'
                      : null
                  }
                />
              </SliderItem>
            );
          })}
      </Slider>
    </div>
  );
};

export default ContentBuilderTemplate;
