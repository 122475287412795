import './AnimatedCircles.scss';
import React, { useEffect, useRef } from 'react';
import { cn } from 'ui/utils/bem';
import { Circle } from '../../../common/components/ContentBuilder/models/CTI_67';

const cnAnimatedCircles = cn('AnimatedCircles');

interface Props {
  circles: Circle[];
}

export const AnimatedCircle: React.FC<Props> = props => {
  const circleRef = useRef<HTMLElement>(null);
  const circleInnerRef = useRef<HTMLDivElement[]>([]);
  const colors = props?.circles?.map(circle => `#${circle.circleColor}`);
  const slideQuantity = colors.length - 1;

  useEffect(() => {
    if (slideQuantity < 1) {
      if (circleRef.current) {
        circleRef.current.style.background = colors[0];
      }
    } else {
      if (circleRef.current) {
        circleRef.current.style.background = colors[0];
        circleRef.current.classList.add('animated');
      }
      let activeEl = 0;

      const calculate = () => {
        if (activeEl < slideQuantity) {
          activeEl += 1;
        } else {
          activeEl = 0;
        }
      };

      const changeColor = () => {
        if (!circleRef || !circleRef.current) {
          return;
        }
        circleRef.current.style.background = colors[activeEl];
      };

      const changeLabel = () => {
        if (circleInnerRef.current) {
          circleInnerRef?.current?.forEach(i => {
            i?.classList?.remove('AnimatedCircles-Inner_active');
          });
          circleInnerRef?.current[activeEl]?.classList?.add(
            'AnimatedCircles-Inner_active'
          );
        }
      };

      const timeoutHandler = setTimeout(() => {
        calculate();
        changeColor();
        changeLabel();

        setInterval(() => {
          calculate();
          changeColor();
          changeLabel();
        }, 2000);
      }, 1700);
      return () => clearTimeout(timeoutHandler);
    }
  }, [circleRef, circleInnerRef]);

  useEffect(() => {
    circleInnerRef.current = circleInnerRef.current.slice(
      0,
      props.circles.length
    );
  }, [props]);

  return (
    <>
      {props?.circles?.length > 0 && (
        <div className={cnAnimatedCircles()} ref={circleRef}>
          {props?.circles.map((circle: any, index: number) => {
            const activeClass = index === 0;
            return (
              <div
                key={circle?.circleValue}
                className={cnAnimatedCircles('Inner', { active: activeClass })}
                ref={el => (circleInnerRef.current[index] = el)}
              >
                <div
                  className={cnAnimatedCircles('Title', {
                    invertedColor: circle.isColorInverted,
                  })}
                >
                  {circle?.circleValue}
                </div>
                <div
                  className={cnAnimatedCircles('SubTitle', {
                    invertedColor: circle.isColorInverted,
                  })}
                >
                  {circle?.circleText}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
