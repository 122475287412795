import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate64.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider } from '@dataartdev/uikit/Slider';
import { SliderItem } from '@dataartdev/uikit/Slider';
import { AwardCard } from '../../AwardCard/AwardCard';
import { cn } from '@bem-react/classname';
import { IRoot64 } from '../models/CTI_64';

export const ContentBuilderTemplate: React.FC<IRoot64> = props => {
  const { type, title, awards, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  const cnAwardsSection = cn('AwardsSection');

  const awardsSlider = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
      767: {
        spaceBetween: 16,
        slidesPerView: 2.25,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 3.25,
      },
      1540: {
        spaceBetween: 24,
        slidesPerView: 4.25,
      },
    },
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnAwardsSection()}>
        {title && <Typography.Title size="lg">{title}</Typography.Title>}
        <Slider {...awardsSlider} className={cnAwardsSection('Slider')}>
          {awards?.map(award => {
            return (
              <SliderItem key={award.id}>
                <AwardCard {...award} />
              </SliderItem>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
