import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { ListLinks } from '../../ListLinks/ListLinks';
import { IRoot52 } from '../models/CTI_52';

export const ContentBuilderTemplate = forwardRefWithAs<IRoot52>(props => {
  const { type, title, subtitle, links = [], contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const modifyLinks = links.map(link =>
    link.targetBlank ? link : { ...link, targetBlank: false }
  );

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid breakpoints={{ desktop: { cols: 12 } }}>
        <GridItem
          breakpoints={{
            desktop: { col: 8 },
            tablet: { col: 10 },
            mobile: { col: 12 },
          }}
        >
          {title && (
            <Typography.Title
              size="lg"
              as="h2"
              className={cnContentBuilderTemplate('Section-Title')}
            >
              {title}
            </Typography.Title>
          )}
          {subtitle && (
            <Typography.Text size="m" as="p">
              {subtitle}
            </Typography.Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, colGap: 'xl' },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 12 },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          <ListLinks
            items={modifyLinks}
            getPath={item => item.path || item.link}
            getLabel={item => item.title}
          />
        </GridItem>
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
