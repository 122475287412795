import React from 'react';

type T_CTA = {
  title: string;
  link: string;
  isCTA: boolean;
};
type T_EXTERNAL_LINK = {
  as: keyof React.JSX.IntrinsicElements;
  href: string;
  target: string;
  label: string;
};
type T_REACT_LINK = {
  isLink: boolean;
  to: string;
  target: string;
  label: string;
};

type ButtonTypes = T_CTA | T_EXTERNAL_LINK | T_REACT_LINK;

export const prepareButtonAttrs = (
  link?: string,
  linkTitle?: string,
  targetBlank?: boolean
): ButtonTypes | {} => {
  if (!link || !linkTitle) return {};

  const isCTA = link.includes('#');
  const isExternal = link.includes('http');
  const target = targetBlank ? '_blank' : '_self';

  if (isCTA) {
    return {
      title: linkTitle,
      link: link.replace('#', ''),
      isCTA,
    };
  }

  if (isExternal) {
    return {
      as: 'a',
      href: link,
      target,
      label: linkTitle,
    };
  }

  return {
    isLink: true,
    to: link || '/404',
    target,
    label: linkTitle,
  };
};

export const hasLabel = (obj: any): obj is T_EXTERNAL_LINK | T_REACT_LINK => {
  return 'label' in obj;
};
