// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { generatePath } from 'react-router-dom';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Button } from '@dataartdev/uikit/Button';
import { EssenceCard } from '../../EssenceCard/EssenceCard';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { useIntl } from 'react-intl';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    text,
    items,
    aliases,
    button,
    linkTitleType0,
    linkTitleType1,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const { locale } = useIntl();

  const options = {
    navigation: items?.length > 3,
    arrows: true,
    breakpoints: {
      767: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
    spaceBetween: 8,
    slidesPerView: 1,
    watchOverflow: true,
  };

  const generateParams = options => {
    switch (options.contentType) {
      case 0:
        return {
          ...options,
          contentType: options.contentType,
          picture: options.picture,
          title: options.title,
          view: 'filled',
          size: 'm',
          path: generatePath(aliases.BLOG_SINGLE, {
            slug: options.slug,
            lang: locale === 'en' ? null : locale,
          }),
          linkTitle: linkTitleType0,
          linkTitleType1,
          url: options.url,
          videoUrl: options.videoUrl,
          video: options.video,
          postDate: null,
        };

      case 2:
        return {
          ...options,
          contentType: options.contentType,
          picture: options.picture,
          title: options.title,
          view: 'filled',
          size: 'm',
          linkTitle: linkTitleType1 || 'watch on youtube',
          url: options.url,
          videoUrl: options.videoUrl,
          video: options.video,
          postDate: null,
        };

      case 3:
        return {
          ...options,
          routePath: generatePath(aliases.EVENTS_PAGE, {
            slug: options.slug,
            lang: locale === 'en' ? null : locale,
          }),
        };

      case 4:
        return {
          ...options,
          contentType: options.contentType,
          picture: options.picture,
          routePath: options.link,
          targetBlank: options.targetBlank,
          title: options.title,
          view: 'filled',
          size: 'm',
          linkIcon: IconOpenInNew,
          linkTitle: options.linkTitle,
          redirectTo: options.link,
        };

      case 5:
        return {
          ...options,
          view: 'filled',
          size: 'm',
          linkTitle: 'learn more',
          path: generatePath(aliases.NEWSROOM_PAGE, {
            slug: options.slug,
            parentSlug: options.parentSlug,
            lang: locale === 'en' ? null : locale,
          }),
        };

      case 6:
        return {
          ...options,
          view: 'filled',
          size: 'm',
          linkTitle: 'learn more',
          linkIcon: IconOpenInNew,
          path: generatePath(aliases.INDUSTRIES_PAGE_INNER, {
            slug: options.industrySlug,
            inner_page: options.slug,
            lang: locale === 'en' ? null : locale,
          }),
        };

      case 8:
        return {
          ...options,
          path: generatePath(aliases.NEWSROOM_PAGE, {
            slug: options.slug,
            parentSlug: options.parentSlug,
            lang: locale === 'en' ? null : locale,
          }),
        };

      case 10:
        return {
          ...options,
          routePath: generatePath(aliases.CASE_PAGE, {
            slug: options.slug,
            lang: locale === 'en' ? null : locale,
          }),
        };

      default:
        return {
          ...options,
        };
    }
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Article
          content={text}
          className={cnContentBuilderTemplate('SubTitle')}
        />
      )}
      {items && (
        <Slider {...options}>
          {items.map((post, index) => (
            <SliderItem key={post.id}>
              <EssenceCard {...generateParams(post)} />
            </SliderItem>
          ))}
        </Slider>
      )}
      {button && (
        <div className={cnContentBuilderTemplate('Button')}>
          <Button {...button} />
        </div>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
