import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate67.scss';
import '../../../../features/industries/pages/SingleIndustries.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { cn } from '@bem-react/classname';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Article } from '../../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconSoon } from '@dataartdev/uikit/IconSoon';
import { AnimatedCircle } from '../../../../features/industries/pages/AnimatedCircle';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';
import { CtaScrollerBtn } from 'common/components/CtaScrollerBtn';
import { IRoot67 } from '../models/CTI_67';

const cnPageHeader = cn('PageHeader');
const cnSingleIndustries = cn('SingleIndustries');

export const ContentBuilderTemplate: React.FC<IRoot67> = props => {
  const {
    type,
    title,
    text,
    circles = [],
    links,
    button,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);
  const typeNumber = type?.toString();
  const buttonAttr = prepareButtonAttrs(
    button?.link,
    button?.title,
    button?.targetBlank
  );

  return (
    <div className={cnPageHeader({ type: typeNumber })} id={contentId}>
      <Grid
        className={cnSingleIndustries('Head')}
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: 'xl' },
          mobile: { cols: 12, gap: 'xl' },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 7 },
            tablet: { col: 7 },
            mobile: { col: 12 },
          }}
        >
          {title && (
            <Typography.Title
              size="hg"
              className={cnSingleIndustries('Title')}
              as="h1"
              parse
            >
              {title}
            </Typography.Title>
          )}
          {text && (
            <GridItem breakpoints={{ desktop: { col: 8 }, tablet: { col: 8 } }}>
              <Article content={text} className={cnSingleIndustries('Text')} />
            </GridItem>
          )}
          {Object.keys(buttonAttr).length > 0 && (
            <div className={cnSingleIndustries('Cta')}>
              {'isCTA' in buttonAttr ? (
                <CtaScrollerBtn {...buttonAttr} />
              ) : (
                <Button
                  view="primary"
                  iconRight={IconArrowRight}
                  {...buttonAttr}
                />
              )}
            </div>
          )}
          {links && links.length > 0 && (
            <Grid
              breakpoints={{
                desktop: { cols: 2, rowGap: 'm', colGap: 'xl' },
                tablet: { cols: 1, gap: 'm' },
                mobile: { cols: 2, gap: 'm' },
              }}
              className={cnSingleIndustries('Links')}
            >
              {links.map(item => {
                const { id, link, title, targetBlank } = item || {};
                const buttonAttr = prepareButtonAttrs(link, title, targetBlank);
                return (
                  <GridItem col="1" key={id}>
                    {Object.keys(buttonAttr).length > 0 &&
                      ('isCTA' in buttonAttr ? (
                        <CtaScrollerBtn {...buttonAttr} view="link-primary" />
                      ) : (
                        <Button
                          className={cnSingleIndustries('Squad-Link')}
                          iconRight={link ? IconArrowRight : IconSoon}
                          view="link-primary"
                          disabled={!link}
                          inline
                          {...buttonAttr}
                        />
                      ))}
                  </GridItem>
                );
              })}
            </Grid>
          )}
        </GridItem>
        <GridItem
          breakpoints={{
            desktop: { col: 4, colStart: 9 },
            tablet: { col: 5 },
            mobile: { col: 12 },
          }}
          className={cnSingleIndustries('Circle-Container')}
        >
          {circles && circles?.length > 0 && (
            <AnimatedCircle circles={circles} />
          )}
        </GridItem>
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
