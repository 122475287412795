/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck

import './EventCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { IconPlay } from '@dataartdev/uikit/IconPlay';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { Typography, TitleProps } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';

export type Props = {
  routePath?: string;
  view?: string;
  picture?: object;
  date?: string;
  tags?: string;
  isOnline?: string;
  languages?: string;
  title?: string;
  slug?: string;
  size?: 'l';
  invertedStyle?: any;
  locations?: any;
  speakers?: any;
};

export const cnEventCard = cn('EventCard');

const sizeMapTitle: Record<Props, TitleProps> = {
  m: 'sm',
  l: 'lg',
};

export const EventCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    routePath,
    view,
    picture,
    date,
    tags,
    isOnline,
    languages,
    title,
    size = 'm',
    invertedStyle,
    locations,
    speakers,
    slug,
    registrationStatus,
    redirectTo,
    eventType,
    fullUrl,
    eventTypeText,
    location,
    eventWithoutPage,
    onlineTitle,
    video,
    cardDictionary,
  } = usePropsHandler(cnEventCard(), props);

  const { eventCard } = cardDictionary || {};

  let redirectToPage = redirectTo;

  if (
    Number(process.env.REACT_APP_EVENT_TYPE) !== Number(eventType) &&
    !redirectToPage &&
    fullUrl
  ) {
    redirectToPage = fullUrl;
  }

  return (
    <div
      className={cnEventCard({
        view,
        size,
        invertedStyle,
        noHover: eventWithoutPage,
      })}
    >
      {!eventWithoutPage && (
        <>
          {redirectToPage && (
            <a
              className={cnEventCard('Link')}
              href={redirectToPage}
              target="_blank"
              rel="noreferrer"
              aria-label={title}
            />
          )}
          {!redirectToPage && routePath && (
            <NavLink
              className={cnEventCard('Link')}
              to={routePath}
              aria-label={title}
            />
          )}
        </>
      )}

      {picture && (
        <div className={cnEventCard('Picture')}>
          <Picture {...picture} />
        </div>
      )}
      <div className={cnEventCard('Inner')}>
        <div className={cnEventCard('Date')}>
          <Typography.Text size="xs" weight="bold" transform="uppercase">
            {date}
          </Typography.Text>
          {locations && (
            <Typography.Text size="xs" weight="bold" transform="uppercase">
              |{' '}
              {isOnline
                ? eventCard?.eventCardOnline ?? onlineTitle
                : locations.map(
                    location => `${location.country}, ${location.location}`
                  )}
            </Typography.Text>
          )}
          {location && (
            <Typography.Text size="xs" weight="bold" transform="uppercase">
              |{' '}
              {isOnline
                ? eventCard?.eventCardOnline ?? onlineTitle
                : `${location.country}, ${location.location}`}
            </Typography.Text>
          )}
          {!location && !locations && isOnline && (
            <Typography.Text size="xs" weight="bold" transform="uppercase">
              |&nbsp;
              {isOnline ? eventCard?.eventCardOnline ?? onlineTitle : null}
            </Typography.Text>
          )}
        </div>
        <div className={cnEventCard('Title')}>
          <Typography.Title
            size={getSizeByMap(sizeMapTitle, size)}
            weight="bold"
            as="h3"
          >
            {title}
          </Typography.Title>
        </div>
        {eventTypeText && (
          <div className={cnEventCard('SubTitle')}>
            <Typography.Text size="s">{eventTypeText}</Typography.Text>
          </div>
        )}
        <div className={cnEventCard('Tags')}>
          {tags && <TagGroup items={tags} />}
        </div>
        <div className={cnEventCard('Speakers')}>
          {video && (
            <div
              className={cnEventCard('VideoCircle')}
              style={{ zIndex: speakers?.length > 0 ? speakers.length + 1 : 1 }}
            >
              <Button
                view="primary"
                form="circle"
                iconRight={IconPlay}
                size="4xl"
                iconSize="s"
                onlyIcon
                aria-label="play button"
              />
            </div>
          )}
          {speakers &&
            speakers.map((speaker, index) => (
              <React.Fragment key={index + slug + title}>
                {speaker.photo && (
                  <User
                    size="m"
                    avatarUrl={speaker.photo?.url}
                    style={{ zIndex: speakers.length - index }}
                  />
                )}
              </React.Fragment>
            ))}
        </div>
        {languages && (
          <div className={cnEventCard('Bottom')}>
            <TagGroup items={languages} mode="label" gap="xs" />
          </div>
        )}
        {registrationStatus && (
          <div className={cnEventCard('Bottom')}>
            <Tag
              mode="label"
              label={registrationStatus.title}
              colorBorder={registrationStatus.color}
              gap="xs"
            />
          </div>
        )}
      </div>
    </div>
  );
});
