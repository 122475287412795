import React from 'react';
import { cn } from '../../utils/bem';
import './FeaturedCard.scss';
import { Typography } from '@dataartdev/uikit/Typography';
import { Tag } from '@dataartdev/uikit/Tag';
import { Picture } from '@dataartdev/uikit/Picture';
import { Case, Logo } from '../ContentBuilder/models/CTI_18';
import { Item } from '../ContentBuilder/models/CTI_104';

const cnFeaturedCard = cn('FeaturedCard');

function isItem(props: Item | Case): props is Item {
  return (props as Item).type !== undefined;
}

function isCase(props: Item | Case): props is Case {
  return (props as Case).title !== undefined;
}

interface CardProps {
  link: string;
  picture?: Logo;
  type?: string;
  title?: string;
  tag?: string;
}

const FeaturedCardContent: React.FC<CardProps> = ({
  link,
  picture,
  type,
  title,
  tag,
}) => (
  <a href={link} className={cnFeaturedCard()}>
    {picture && <Picture {...picture} />}
    <div className={cnFeaturedCard('Inner')}>
      <div className={cnFeaturedCard('Top')}>
        {type && (
          <Typography.Text
            parse
            as="span"
            size="xs"
            transform="uppercase"
            weight="bold"
            className={cnFeaturedCard('Subtitle')}
          >
            {type}
          </Typography.Text>
        )}
        {title && (
          <Typography.Title
            size="tiny"
            as="h3"
            className={cnFeaturedCard('Title')}
          >
            {title}
          </Typography.Title>
        )}
      </div>
      {tag && <Tag mode="link" label={tag} className={cnFeaturedCard('Tag')} />}
    </div>
  </a>
);
export const FeaturedCard: React.FC<Item | Case> = props => {
  if (isItem(props)) {
    const { tag = '', text = '', type = '', link = '/', picture } = props || {};
    return (
      <FeaturedCardContent
        link={link}
        picture={picture}
        type={type}
        title={text}
        tag={tag}
      />
    );
  }
  if (isCase(props)) {
    const { title, logo, categoryText, tags = [], fullUrl = '/' } = props || {}; // for Case type

    return (
      <FeaturedCardContent
        link={fullUrl}
        picture={logo}
        type={categoryText}
        title={title}
        tag={tags[0].title}
      />
    );
  }
  return null;
};
