import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate80.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '../../../utils/bem';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { PromoLinkCard } from '../../PromoLinkCard/PromoLinkCard';
import { FilterTag } from '../../FilterTag/FilterTag';
import { IRoot80 } from '../models/CTI_80';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { useLocation } from 'react-router-dom';

const cnFilteredCards = cn('FilteredCards');

export const ContentBuilderTemplate = forwardRefWithAs<IRoot80>(props => {
  const { type, items, title, tagItems, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const initialContent = items && items.length > 0 ? items : [];
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const tagHandler = useCallback(
    (tag: string) => {
      if (tag === selectedTag) {
        setSelectedTag(null);
        return;
      }
      setSelectedTag(tag);
    },
    [selectedTag]
  );

  const filteredContent = useMemo(() => {
    if (!selectedTag) return initialContent;
    return (
      items?.filter(item =>
        item?.tagItems?.some(i => i.slug.toLowerCase() === selectedTag)
      ) || initialContent
    );
  }, [items, selectedTag, initialContent]);

  const { hash } = useLocation();
  useEffect(() => {
    if (!hash) return;
    const hashedTag = tagItems?.find(
      tag => tag.slug.toLowerCase() === hash.substring(1)
    );
    if (!hashedTag) return;
    tagHandler(hashedTag.slug.toLowerCase());
  }, [hash, tagItems]);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnFilteredCards()}>
        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 12 },
            mobile: { cols: 12 },
          }}
        >
          {title && (
            <GridItem
              breakpoints={{
                desktop: { col: 12 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
              className={cnFilteredCards('Title')}
            >
              <Typography.Title size="lg" as="h2" parse>
                {title}
              </Typography.Title>
            </GridItem>
          )}
          {tagItems && tagItems.length > 0 && (
            <GridItem
              breakpoints={{
                desktop: { col: 12 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
              className={cnFilteredCards('Tags')}
            >
              {tagItems.map(tag => (
                <FilterTag
                  key={tag.slug}
                  active={tag.slug.toLowerCase() === selectedTag}
                  {...tag}
                />
              ))}
            </GridItem>
          )}
          {filteredContent && filteredContent.length > 0 && (
            <GridItem
              breakpoints={{
                desktop: { col: 12 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
              className={cnFilteredCards('List')}
            >
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 12, gap: 'xl' },
                  mobile: { cols: 12, gap: 'xl' },
                }}
              >
                {filteredContent.map(item => (
                  <GridItem
                    key={item.id}
                    breakpoints={{
                      desktop: { col: 3 },
                      tablet: { col: 4 },
                      mobile: { col: 12 },
                    }}
                    className={cnFilteredCards('Item')}
                  >
                    <PromoLinkCard {...item} />
                  </GridItem>
                ))}
              </Grid>
            </GridItem>
          )}
        </Grid>
      </div>
    </div>
  );
});

export default ContentBuilderTemplate;
