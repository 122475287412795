import './CaseCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { Case } from '../ContentBuilder/models/CTI_18';

export const cnCaseCard = cn('CaseCard');

export const CaseCard: React.FC<Case> = props => {
  const {
    color,
    hasClient,
    logo,
    routePath,
    fullUrl,
    shortDescription,
    title,
    buttonTitle,
    buttonIcon = IconArrowRight,
    style,
    size,
    subtitle,
    showTitle = false,
    showButton,
  } = usePropsHandler(cnCaseCard(), props);

  return (
    <div
      className={cnCaseCard({
        main: !hasClient,
        border: !!color && !hasClient,
        size: size,
      })}
      style={style}
    >
      {!hasClient && color && (
        <div
          className={cnCaseCard('Border')}
          style={{ backgroundColor: color }}
        />
      )}

      {fullUrl && (
        <NavLink
          to={{ pathname: fullUrl }}
          target="_blank"
          className={cnCaseCard('OverlayLink')}
          aria-label={title}
        />
      )}

      {subtitle && (
        <Typography.Text
          className={cnCaseCard('SubTitle')}
          size="xs"
          weight="bold"
          transform="uppercase"
        >
          {subtitle}
        </Typography.Text>
      )}

      {(!hasClient || showTitle) && (
        <Typography.Title className={cnCaseCard('Title')} size="sm" as="h3">
          {title}
        </Typography.Title>
      )}

      {hasClient && !!logo && Object.keys(logo).length > 0 && (
        <Picture
          className={cnCaseCard('Logo')}
          desktop={logo.url}
          desktopWebp={logo.urlWebp}
        />
      )}

      {shortDescription && (
        <Typography.Text className={cnCaseCard('Text')}>
          {shortDescription}
        </Typography.Text>
      )}

      {fullUrl && !hasClient && (
        <div className={cnCaseCard('Bottom')}>
          <Button
            view="link-secondary"
            iconSize={'m'}
            label={buttonTitle}
            iconRight={buttonIcon}
            isLink={!!routePath}
            to={routePath}
          />

          {fullUrl && !!logo && Object.keys(logo).length > 0 && (
            <Picture
              className={cnCaseCard('Logo')}
              desktop={logo.url}
              desktopWebp={logo.urlWebp}
            />
          )}
        </div>
      )}
    </div>
  );
};
